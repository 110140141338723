import {Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from "rxjs/webSocket";

@Injectable({
  providedIn: 'root'
})
export class WsocketService {

  subject: WebSocketSubject<any>;

  constructor() {
  }

  public connect(stationRemoteId: string) {
    console.log("Trying to connect to WS");
    this.disconnect();
    this.subject = webSocket('wss://stops.o7.com:8008/smart-stops/ws/socket');
    return this.subject;
  }

  public disconnect() {
    console.log("Disconnectiong from websocket")
    if (this.subject && !this.subject.closed) {
      this.subject.unsubscribe();
      this.subject.complete();
    }
  }
}
