import {Injectable} from '@angular/core';
import {WsocketService} from './wsocket.service';
import {Observable} from "rxjs";
import {filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ForecastService {

  private selectedStation: StationInfo;

  private observableStationOpen: Observable<any>;

  stations: StationInfo[] = [
    {
      stationRemoteId: 'Orenburg:51.773898:55.143401',
      lat: 51.773898,
      lng: 55.143401,
      label: 'Молодежная (в центр)'
    }
  ];

  routesMap: Map<string, string> = new Map<string, string>();

  constructor(private wsocketService: WsocketService) {

  }

  public getSelectedStation = () => this.selectedStation ? this.selectedStation : this.stations[0];

  public connect(stationInfo: StationInfo): Observable<Forecast> {
    this.selectedStation = stationInfo;

    const webSocketSubject = this.wsocketService.connect(stationInfo.stationRemoteId);
    this.observableStationOpen = webSocketSubject.pipe(filter(message => message['type'] === 'sessionOpen'));

    this.observableStationOpen.subscribe(msg => {
      this.onSessionOpen(msg);
    });

    const stationUpdates = webSocketSubject.pipe(filter(message => message['type'] === 'stationUpdate'));

    webSocketSubject.next({type: 'sessionOpen', request: {station_remote_id: stationInfo.stationRemoteId}});

    return stationUpdates.pipe(map(msg => this.onStationUpdate(msg)));
  }

  public disconnect() {
    this.wsocketService.disconnect();
  }

  timeInMin(time) {
    if (time == null) {
      return null;
    }
    return Math.ceil((time / 60000) + 0.5);
  };

  dateForecast(time) {
    if (time == null) {
      return null;
    }
    return new Date(time);
  }

  onSessionOpen(message: any) {
    console.log('received SessionOpen message: ', message);
    const updatedRoutesMap = new Map<string, string>();
    if (message['response'] != null && message['response']['routes'] != null) {
      const routesInfo = message['response']['routes'];
      for (const r of routesInfo) {
        updatedRoutesMap.set(r['id'], r['name'] + ' ' + r['vehicleType']);
      }
    }
    this.routesMap = updatedRoutesMap;
    console.log(this.routesMap);
  };


  onStationUpdate(message: any): Forecast {
    console.log('received StationUpdate message: ', message);

    const forecast: Forecast = {
      vehicles: [],
      arrivals: [],
      updateTime: new Date()
    };

    if (message['response'] != null && message['response']['station'] != null
      && message['response']['station']['stationRoutePositions'] != null) {
      const stationRoutePositions = message['response']['station']['stationRoutePositions'];
      const arrivalsArray = message['response']['arrivals'];

      for (const p of stationRoutePositions) {
        const forecastMin = this.timeInMin(p['waitingTimeMs']);
        const vehicleName = (p['vehicle'] != null ? p['vehicle']['gosnum'] : '');
        const vehicleId = (p['vehicle'] != null ? p['vehicle']['id'] : '');
        const routeNum = this.routesMap.get(p['routeId']);
        const speed = p['vehicle'] != null ? p['vehicle']['speed'] : '';
        const vehicle: Vehicle = {
          routeNum: routeNum,
          label: routeNum + ', ' + vehicleName + ', скорость: ' + speed + ', прогноз: ' + forecastMin +', id: '+ vehicleId,
          lastForecast: this.dateForecast(p['lastForecast']),
          vehicleNo: p['vehicle'] != null ? p['vehicle']['gosnum'] : '',
          speed: p['vehicle'] != null ? p['vehicle']['speed'] : '',
          imei: p['vehicle'] != null ? p['vehicle']['imei'] : '',
          lat: (p['vehicle'] != null && p['vehicle']['vehiclePosition'] != null && p['vehicle']['vehiclePosition']['location'] != null) ?
            p['vehicle']['vehiclePosition']['location']['y'] : null,
          lng: (p['vehicle'] != null && p['vehicle']['vehiclePosition'] != null && p['vehicle']['vehiclePosition']['location'] != null) ?
            p['vehicle']['vehiclePosition']['location']['x'] : null,
          forecastMin: forecastMin
        };
        console.log(vehicle);
        forecast.vehicles.push(vehicle);
      }

      for(const p of arrivalsArray){
        const arrival = this.toArrival(p);
        forecast.arrivals.push(arrival);
      }
    }

    return forecast;
  };

  private toArrival(p: any): Arrival{
    const routeNum = this.routesMap.get(p['routeId']);
    const vehicleId = p['vehicleId'];
    const distanceToStation = p['distanceToStation'];
    const arrivalTime = this.dateForecast(p['arrivalTime'])

    return {
      vehicleId: vehicleId,
      routeNum: routeNum,
      arrivalTime: arrivalTime,
      distanceToStation: distanceToStation
    }
  }
}


export interface Vehicle {
  lat: number;
  lng: number;
  label?: string;
  routeNum?: string;
  lastForecast?: Date;
  forecastMin?: number;
  vehicleNo?: string;
  speed?: number;
  imei?: string;
}

export interface StationInfo {
  stationRemoteId: string;
  lat: number;
  lng: number;
  label: string;
}

export interface Arrival {
  vehicleId: string;
  routeNum: string;
  arrivalTime: Date;
  distanceToStation: number;
}

export interface Forecast {
  vehicles: Vehicle[],
  arrivals: Arrival[],
  updateTime: Date
}

