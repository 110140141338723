import {Component, OnInit} from '@angular/core';
import {icon, latLng, Map, marker, Marker, tileLayer} from "leaflet";
import {Forecast, ForecastService, StationInfo, Vehicle} from "../forecast.service";
import {Observable} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-osm',
  templateUrl: './osm.component.html',
  styleUrls: ['./osm.component.scss']
})
export class OsmComponent implements OnInit {

  form = new FormGroup({
    station: new FormControl(this.forecastService.getSelectedStation()),
  });

  private selectedStation: StationInfo;

  private forecast: Forecast = {
    vehicles: [], arrivals: [], updateTime: null
  };

  private map: Map;
  private mapOptions = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 20, attribution: 'OpenStreetMap'})
    ],
    zoom: 17,
    center: latLng(51.773898, 55.143401)
  };

  private stationMarker: Marker;
  private vehiclesMarkers: Marker[] = [];

  private observableStationUpdates: Observable<Forecast>;


  constructor(private forecastService: ForecastService) {

  }

  ngOnInit() {
  }

  onMapReady(map: Map) {
    console.log("Map ready")
    this.map = map;
    console.log("Selected station: ", this.selectedStation);
    this.connectButtonClicked();
  }

  stationToMarker(stationInfo: StationInfo): Marker {
    return marker([stationInfo.lat, stationInfo.lng], {
      icon: icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png'
      }),
      title: stationInfo.label
    })
  }

  private connectButtonClicked() {
    this.selectedStation = this.forecastService.getSelectedStation();
    this.onStationSelected();
    this.observableStationUpdates = this.forecastService.connect(this.selectedStation);
    this.observableStationUpdates.subscribe(forecast => {
      this.onForecastReceived(forecast);
    })
  }

  private mapFlyToSelectedStation = () => {
    const center = this.selectedStation ? latLng(this.selectedStation.lat, this.selectedStation.lng) : latLng(55.011656, 82.931834);
    this.map.flyTo(center);
  };

  onStationSelected() {
    console.log('Station selected: ', this.selectedStation);
    if (this.stationMarker) {
      this.stationMarker.remove();
    }

    this.stationMarker = this.stationToMarker(this.selectedStation);
    this.stationMarker.addTo(this.map);
    this.mapFlyToSelectedStation();
  }

  onForecastReceived(forecast: Forecast) {
    console.log('Forecast received: ', forecast);
    this.forecast = forecast;

    this.vehiclesMarkers.forEach(marker => {
      marker.remove()
    });

    if (forecast && forecast.vehicles) {
      this.vehiclesMarkers = forecast.vehicles
        .filter(v => (v.lat != null && v.lng != null))
        .map(v => this.vehicleToMarker(v));
    }

    this.vehiclesMarkers.forEach(marker => marker.addTo(this.map));
  }


  private vehicleToMarker(vehicle: Vehicle) {
    return marker([vehicle.lat, vehicle.lng], {
      icon: icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png'
      }),
      title: vehicle.label
    })
  }
}
