import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OsmComponent} from "./osm/osm.component";


const routes: Routes = [{path: '', component: OsmComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
